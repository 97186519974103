import React, { useRef } from 'react';
import LandingHeader from '../LocationLanding/LandingHeader';
import LandingMastHead from '../LocationLanding/LandingMastHead';
import LocationFinder from '../locationFinder/locationFinder';

const LocationFinderCardLock = () => {
  const header = useRef();
  return (
    <div className="tw-grid tw-grid-rows-[auto_1fr] ">
      <div ref={header}>
        <LandingHeader />
        <LandingMastHead />
      </div>
      <div>
        <LocationFinder heightOffsetRef={header} />
      </div>
    </div>
  );
};

export default LocationFinderCardLock;
