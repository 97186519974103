import React from 'react';
import { Icons } from '../Icons/Icons';

const LandingHeader = () => {
  return (
    <div className="tw-px-12 tw-py-4 md:tw-py-12 tw-bg-gradient-to-t tw-from-blue-light tw-to-blue">
      <div className="tw-flex tw-gap-4 md:tw-gap-8 xl:tw-gap-12 tw-text-white tw-justify-center">
        <img
          src="../../../img/logos/Ultramar-Logo.png"
          alt="ultramar logo"
          className="tw-w-1/4 tw-max-w-[50px] md:tw-max-w-[80px] "
        />
        <Icons.TransactionLogo className="tw-w-1/4 tw-max-w-[200px] " />
        <Icons.PipelineLogo className="tw-w-1/4 tw-max-w-[150px]" />
      </div>
    </div>
  );
};

export default LandingHeader;
