import React, { useEffect, useState } from 'react';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import Header from '../../components/header/header';
import LanguageModal from '../../components/languageModal/languageModal';
import GlobalStyles from '../../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from '../../i18next';
import Footer from '../../components/footer/footer';
import {
  isValidURLLanguage,
  isValidURLRegion,
  validNodeLocale,
} from '../../utils';
import Cookies from 'universal-cookie';
import LocationDefault from '../../components/locationDefault/locationDefault';
import LocationFinder from '../../components/locationFinder/locationFinder';
import LandingHeader from '../../components/LocationLanding/LandingHeader';
import LandingMastHead from '../../components/LocationLanding/LandingMastHead';
import LocationFinderCardLock from '../../components/LandingPages/LocationFinderCardLock';

const LocationPageTemplateRevised = ({ location }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loaded, setLoaded] = React.useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const cookies = new Cookies();
  const date = new Date();
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);
  const queryParams = new URLSearchParams(location?.search);
  const regionQueryParams = queryParams.get('region')?.toUpperCase();

  typeof window !== 'undefined' &&
    window?.history?.replaceState({}, document?.title, location?.pathname);

  const updateAndSetRegionCookie = () => {
    if (
      isValidURLRegion(regionQueryParams) &&
      cookies.get('region')?.toUpperCase() !== regionQueryParams
    ) {
      cookies.set('region', regionQueryParams?.toLowerCase(), {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return regionQueryParams;
    }
  };
  const selectedRegion =
    isValidURLRegion(regionQueryParams) &&
    cookies.get('region')?.toUpperCase() !== regionQueryParams
      ? updateAndSetRegionCookie()
      : cookies.get('region')?.toUpperCase()
      ? cookies.get('region')?.toUpperCase()
      : 'NB';

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };
  const selectedLanguage =
    isValidURLLanguage(urlLanguage) &&
    validNodeLocale(urlLanguage) &&
    cookies.get('language') !== validNodeLocale(urlLanguage)
      ? updateAndSetLanguageCookie(validNodeLocale(urlLanguage))
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setLoaded(true);
    AOS.init();
    AOS.refresh();
  }, []);

  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    if (typeof window === 'undefined') {
      i18n.changeLanguage(lang === 'fr-CA' ? 'fr' : 'en');
    }
  };

  return !loaded ? (
    <>
      {changeLanguageHandler(selectedLanguage)}
      <I18nextProvider i18n={i18next}>
        <GlobalStyles />
        {/* <Header
          scrollPosition={scrollPosition}
          englishSlug={location?.pathname
            ?.replace('fr', 'en')
            ?.replace('sites', 'locations')}
          frenchSlug={location?.pathname
            ?.replace('en', 'fr')
            ?.replace('locations', 'sites')}
        /> */}
        {/* <LocationDefault
          selectedLanguage={selectedLanguage}
          selectedRegion={selectedRegion}
        /> */}
        <BackToTopScroll scrollPosition={scrollPosition} />
        <Footer />
      </I18nextProvider>
    </>
  ) : (
    <>
      <I18nextProvider i18n={i18next}>
        <GlobalStyles />
        <LanguageModal />
        <LocationFinderCardLock />
        <BackToTopScroll scrollPosition={scrollPosition} />
      </I18nextProvider>
    </>
  );
};

export default LocationPageTemplateRevised;
