import React from 'react';
import { useTranslation } from 'react-i18next';

const LandingMastHead = () => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto text-center tw-px-12 tw-py-4 md:tw-py-12 tw-max-w-2xl">
      <h1 className="tw-font-mono tw-text-blue text-balance">
        {t('Find your closest fleet or cardlock station in our network.')}
      </h1>
      <p className="text-balance tw-mb-0">
        {t(
          'Ultramar continues to look forward to meeting your fuelling needs at a location near you. With convenient and fast fuelling stations along major Ontario and Québec routes you will always feel supported out on the road.'
        )}
      </p>
    </div>
  );
};

export default LandingMastHead;
